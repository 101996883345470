<script>
import loginRegModal from "../popUpModals/loginReg";
import footerLayout from "../../layouts/footerLayout";
import Drawer from "vue-simple-drawer"


import Vue from 'vue';

export default {
    data() {
      return {
          policyType: '',
		   openMenuDrawer: false,
      }
    },

    components: {
				 footerLayout,
                 loginRegModal,
                 Drawer
                },
    mounted() {
        if(this.$route.params.policy){
                this.policyType = this.$route.params.policy;
            }
		this.getUserData();

    },
    methods: {

        userRedirection(routerName)
		{
			if(this.userName != '')
			{
                if (routerName != '/checkOut') {
					window.location.href = routerName;
                    return;
                }
				if (this.cartItemsDetailsHead.length > 0 && routerName == '/checkOut') {
					this.openMenuDrawer = false;
					// this.$router.push(routerName);	
					window.location.href = routerName;
				}
				else
				{
					alert('No items in cart');
				}
			}
			else
			{
                this.openMenuDrawer = !this.openMenuDrawer;
				this.$bvModal.show("modal-login");
			}
		},

        getUserData()
    {
        this.axios.get('/getUserDeatils')
                    .then((response)=>{
					// console.log(response);
                    // if(response.data.token && response.data.token != '')
					// {
					// 	var userData = {
                    //             "utid":response.data.token,
                    //         }
                    //         localStorage.setItem('uTid',JSON.stringify(userData));
					// } // if token is expired so it can be refreshed
                    
					let status = response.status;
					if(status == 200 && response.data.status == 200)
					{
						if (response.data.userData.userName) {
                            this.userName = response.data.userData.userName;
							this.corporateLogo = response.data.userData.corporateLogo;
                        }
                        else
                        {
                            this.userName = '';
                        }
					}
					// this.cartStatus = response.data.cartdetails;
				}).catch((error) => {
						console.log(error.response.data.message);
				});
    },
        toggleMenuDrawer() {
			this.openMenuDrawer = !this.openMenuDrawer
		},
        openLoginModal()
		{
			this.openMenuDrawer = !this.openMenuDrawer;
			this.$bvModal.show("modal-login");

		},
        logout()
		{
            this.$confirm("Are you sure you want to logout?","Logout","")
				.then(() => {
                                localStorage.clear();
                                Vue.prototype.$userData = '';
                                Vue.prototype.$placesAddress = '';
                                // this.$router.push('/');
                                window.location.href = '/';
                });
        },
        
        pushPolicy(policyTypeTab)
        {
            this.$router.push('/policies/'+policyTypeTab);
        }
    }
}
</script>
<template>
<div>
        <header class="header_in clearfix">
	    <div class="container">
			<div class="row">
				<div class="col-md-4 col-lg-4 col-xl-4 noMobile" style="line-height: 50px;">
					<div id="logo">
						<a href="/" class="home">
							<!-- <i class="mdi mdi-home-variant h5"></i>  -->
                            <i class="fas fa-home"></i>
                            Home
						</a>
					</div>
				</div>
				<div class="col-md-4 col-lg-4 col-xl-4 align-center">
					<div id="mainLogo logo">
						<a href="/">
							<img class="mainLogoImg" src="../../templateAssests/img/cklogo.png" width="121" height="55" alt="">
						</a>
					</div>
				</div>
					<div class="col-md-4 col-lg-4 col-xl-4 align-right rightDiv noMobile" style="margin-top:10px;">
						<!-- <a href="/">
							<i class="mdi mdi-help h5"></i>
                             Help
						</a> -->
                        <a href="tel:+917710999666" class="phneNo">
                            <i class="mdi mdi-phone h5"></i>+91 771 099 9666
                        </a>
                        <br>
                        <a href="mailto:enquiry@cloudkitch.co.in" class="email">
                            <i class="far fa-envelope"></i>enquiry@cloudkitch.co.in
                        </a>
					</div>
					<!-- /top_menu -->
					<a href="#0" class="open_close">
						<i @click="toggleMenuDrawer" class="icon_menu"></i>
							<Drawer @close="toggleMenuDrawer" align="left" :maskClosable="true" :closeable="false">
                                             <div v-if="openMenuDrawer">
												 <section class="head_section">
													<div  v-if="userName != ''" class="row">
														<div class="align-left">
															<!-- <figure> -->
																<img class="userPlaceImage" src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="50" width="50" alt="">
																<!-- </figure> -->
														</div>
														<div class="align-left">
																<a href="/profile/my-profile" class="userName" style="margin:8px 0 0 0; color:#212121; font-size:14px;">{{userName}}</a>
															<!-- <p class="userName userNameTextLen one-line-text" style="margin:8px 0 0 0;">{{userName}}</p> -->
														</div>
													</div>
													<div v-else class="row">
														<div class="align-left">
															<p class="loginReg" @click="openLoginModal">Login / Register</p>
														</div>
													</div>
												 </section>
												 <hr style="margin:0px;">
												 <!-- headMobileMenu -->

												 <section class="navigation_section">
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/"><li><img src="../../templateAssests/img/ckgallery/CloudKitch_logo_solo.png" style="object-fit:contain;margin-right:10px;" width="25" height="25" alt="">Home</li></a>
														<a @click="userRedirection('/checkOut')"><li><img src="../../templateAssests/img/ckgallery/Cart.png" width="25" height="25" style="object-fit:contain;margin-right:10px;" alt="">Cart</li></a>
														<a @click="userRedirection('/profile/my-orders')"><li><i class="icon_bag_alt" style="font-size:20px;margin-right:10px;"></i>My Orders</li></a>
														<a @click="userRedirection('/profile/my-subscriptions')"><li><i class="mdi mdi-calendar-clock" style="font-size:20px;margin-right:10px;"></i>My Subscriptions</li></a>
														<a @click="userRedirection('/profile/my-vouchers')"><li><i class="mdi mdi-ticket-percent-outline" style="font-size:20px;margin-right:10px;"></i>Pre-Buy Vouchers</li></a>
														<a @click="userRedirection('/profile/my-address')"><li><i class="far fa-address-book" style="font-size:20px;margin-right:10px;"></i>My Address</li></a>
														<a @click="userRedirection('/profile/wallet')"><li><i class="mdi mdi-wallet-outline" style="font-size:20px;margin-right:10px;"></i>Wallet</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder">
												 <!-- navigation_section -->

												 <section class="help_section">
													 <h3 class="helpHead align-left">Help & Support</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/policies/terms-and-condition"><li><i class="mdi mdi-file-document-multiple-outline" style="font-size:20px;margin-right:10px;"></i>Terms & Conditions</li></a>
														<a href="/policies/grievance-policy"><li><i class="mdi mdi-file-document-outline" style="font-size:20px;margin-right:10px;"></i>Grievance Policy</li></a>
														<a href="/policies/cookie-policy"><li><i class="mdi mdi-cookie-outline" style="font-size:20px;margin-right:10px;"></i>Cookie Policy</li></a>
														<a href="/policies/cancellation-refund"><li><i class="mdi mdi-cash-refund" style="font-size:20px;margin-right:10px;"></i>Cancellation & Refund</li></a>
														<a href="#"><li><i class="icon_mail_alt" style="font-size:20px;margin-right:10px;"></i>Contact Us</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder">
												 <!-- help_section -->

												 <section class="other_section" v-if="userName != ''">
													 <h3 class="helpHead align-left">Other</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a @click="logout"><i class="uil uil-sign-out-alt" style="font-size:20px;margin-right:10px;"></i>Logout</a></li>
													</ul> 
												 </section>
												 <!-- <hr class="hrBorder"> -->
												 <!-- navigation_section -->
                                                 
                                             </div>
                                        </Drawer>
					</a>
			</div>
			<!-- / row -->
	    </div>
		<!-- / container -->
	

	</header>
	<!-- /header -->
    <main>
        <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2 tabsPostion"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab title="Terms & Conditions" @click="pushPolicy('terms-and-condition')" :active="policyType == 'terms-and-condition'" title-item-class="mb-2">
                <b-card-text>
                    <div class="section-services container">
                        <h2 class="setionTitle align-center">Terms &amp; Conditions</h2>
                        <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of <a href="http://cloudkitch.co.in/" target="_blank">www.cloudkitch.co.in</a> website and CLOUDKITCH application for mobile and handheld devices.</p>
                        <h2 class="policyHead">Terms Of Use</h2>
                        <ul>
                            <li><p>These terms of use (the "&nbsp;Terms of Use") govern your use of our website www.cloudkitch.co.in (the "&nbsp;Website") and our "CLOUDKITCH" application for mobile and handheld devices (the "&nbsp;App"). The Website and the App are jointly referred to as the "&nbsp;Platform". Please read these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or even merely using the Platform, you shall be contracting with CLOUDKITCH and you signify your acceptance to the Terms of Use and other CLOUDKITCH policies (including but not limited to the Cancellation &amp; Refund Policy, Privacy Policy and Take Down Policy) as posted on the Platform from time to time, which takes effect on the date on which you download, install or use the Services, and create a legally binding arrangement to abide by the same.</p></li>
                            <li><p>The Platform is operated and owned by CLOUD KITCH Private Limited, a company incorporated under the Companies Act, 1956 and having its registered office at 1403, Tower 3, Indiabulls Finance Centre, Senapati Bapat Marg, Elphinestone Road, Mumbai, Maharashtra, Mumbai- 400013. For the purpose of these Terms of Use, wherever the context so requires, "you" shall mean any natural or legal person who has agreed to become a buyer or customer on the Platform by providing Registration Data while registering on the Platform as a registered user using any computer systems. The terms "CLOUDKITCH", "we", "us" or "our" shall mean CLOUD KITCH Private Limited.</p></li>
                            <li><p>CLOUDKITCH enables transactions between participant restaurants/merchants and buyers, dealing in prepared food and beverages ("&nbsp;Platform Services"). The buyers ("&nbsp;Buyer/s") can choose and place orders ("&nbsp;Orders") from variety of products listed and offered for sale by various neighbourhood merchants including but not limited to the restaurants and eateries ("&nbsp;Merchant/s"), on the Platform and CLOUDKITCH enables delivery of such orders at select localities of serviceable cities across India ("&nbsp;Delivery Services"). The Platform Services and Delivery Services are collectively referred to as "&nbsp;Services".</p></li>
                            <li><h4>AMENDMENTS</h4> <p>These Terms of Use are subject to modifications at any time. We reserve the right to modify or change these Terms of Use and other CLOUDKITCH policies at any time by posting changes on the Platform, and you shall be liable to update yourself of such changes, if any, by accessing the changes on the Platform. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other CLOUDKITCH policies and note the changes made on the Platform. Your continued usage of the services after any change is posted constitutes your acceptance of the amended Terms of Use and other CLOUDKITCH policies. As long as you comply with these Terms of Use, CLOUDKITCH grants you a personal, non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these Terms of Use, you also accept and agree to be bound by the other terms and conditions and CLOUDKITCH policies (including but not limited to Cancellation &amp; Refund Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the Platform from time to time.</p></li>
                            <li><h4>Use of Platform and Services</h4></li>
                            <li><p>All commercial/contractual terms are offered by and agreed to between Buyers and Merchants alone. The commercial/contractual terms include without limitation price, taxes, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. CLOUDKITCH does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Merchants. CLOUDKITCH may, however, offer support services to Merchants in respect to order fulfilment, payment collection, call centre, and other services, pursuant to independent contracts executed by it with the Merchants.</p></li>
                            <li><p>CLOUDKITCH does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Merchants. You are advised to independently verify the bona fides of any particular Merchant that you choose to deal with on the Platform and use your best judgment in that behalf. All Merchant offers and third party offers are subject to respective party terms and conditions. CLOUDKITCH takes no responsibility for such offers.</p></li>
                            <li><p>CLOUDKITCH neither make any representation or warranty as to specifics (such as quality, value, salability, etc.) of the products or services proposed to be sold or offered to be sold or purchased on the Platform nor does implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. CLOUDKITCH accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</p></li>
                            <li><p>CLOUDKITCH is not responsible for any non-performance or breach of any contract entered into between Buyers and Merchants on the Platform. CLOUDKITCH cannot and does not guarantee that the concerned Buyers and/or Merchants will perform any transaction concluded on the Platform. CLOUDKITCH is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p></li>
                            <li><p>CLOUDKITCH is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between Buyer and Merchant on the Platform come into or take possession of any of the products or services offered by Merchant. At no time shall CLOUDKITCH hold any right, title or interest over the products nor shall CLOUDKITCH have any obligations or liabilities in respect of such contract entered into between Buyer and Merchant.</p></li>
                            <li><p>CLOUDKITCH is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Merchant and the Buyer. In case of complaints from the Buyer pertaining to food efficacy, quality, or any other such issues, CLOUDKITCH shall notify the same to Merchant and shall also redirect the Buyer to the consumer call center of the Merchant. The Merchant shall be liable for redressing Buyer complaints. In the event you raise any complaint on any Merchant accessed using our Platform, we shall assist you to the best of our abilities by providing relevant information to you,such as details of the Merchant and the specific Order to which the complaint relates, to enable satisfactory resolution of the complaint.</p></li>
                            <li><p>Please note that there could be risks in dealing with underage persons or people acting under false pretence.</p></li>
                        </ul>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

              <b-tab title="Grievance Policy" @click="pushPolicy('grievance-policy')" :active="policyType == 'grievance-policy'" title-item-class="mb-2">
                <b-card-text>
                     <div class="section-services container">
                        <h2 class="setionTitle align-center">Grievance Policy</h2>
                        <p>This Privacy Policy <b>(“Policy”)</b> describes the policies and procedures on the collection, use, disclosure and protection of your information when you use our website located at&nbsp;CLOUDKITCH.com, or the CLOUDKITCH mobile application (collectively, <b>“CLOUDKITCH Platform”</b> ) made available by Cloud Kitch Private Limited (<b>“CLOUDKITCH”</b> , <b>“Company”</b>, <b>“we”</b>, <b>“us”</b> and <b>“our”</b> ), &nbsp;a private company established under the laws of India having its registered office at 1302, Tower 3, IndiaBulls Finance Centre, Senapati Bapat Marg, Elphinstone Road, Mumbai, Maharashtra, Mumbai - 400013</p>
                        <p>The terms “you” and “your” refer to the user of the CLOUDKITCH Platform. The term <b>“Services”</b> refers to any services offered by CLOUDKITCH whether on the CLOUDKITCH Platform or otherwise.</p>
                        <p>Please read this Policy before using the CLOUDKITCH Platform or submitting any personal information to CLOUDKITCH. This Policy is a part of and incorporated within, and is to be read along with, the Terms of Use.</p>
                        <h2 class="policyHead">YOUR CONSENT</h2>
                        <p>By using the CLOUDKITCH Platform and the Services, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information as described and collected by us in accordance with this Policy. &nbsp;If you do not agree with the Policy, please do not use or access the CLOUDKITCH Platform.</p>
                        <h2 class="policyHead">POLICY CHANGES</h2>
                        <p>We may occasionally update this Policy and such changes will be posted on this page. If we make any significant changes to this Policy we will endeavour to provide you with reasonable notice of such changes, such as via prominent notice on the CLOUDKITCH Platform or to your email address on record and where required by applicable law, we will obtain your consent. To the extent permitted under the applicable law, your continued use of our Services after we publish or send a notice about our changes to this Policy shall constitute your consent to the updated Policy.</p>
                        <h2 class="policyHead">LINKS TO OTHER WEBSITES</h2>
                        <p>The CLOUDKITCH Platform may contain links to other websites. Any personal information about you collected whilst visiting such websites is not governed by this Policy. CLOUDKITCH shall not be responsible for and has no control over the practices and content of any website accessed using the links contained on the CLOUDKITCH Platform. This Policy shall not apply to any information you may disclose to any of our service providers/service personnel which we do not require you to disclose to us or any of our service providers under this Policy.</p>
                        <h2 class="policyHead">INFORMATION WE COLLECT FROM YOU</h2>
                        <p>We will collect and process the following information about you:</p>
                        <ul>
                            <li><p><b>Information you give us -</b>&nbsp;This includes information submitted when you:</p></li>
                            <li><p>Create or update your CLOUDKITCH account, which may include your name, email, phone number, login name and password, address, payment or banking information, date of birth and profile picture. If you sign in to the CLOUDKITCH Platform through third-party sign-in services such as Facebook, Google Plus or Gmail or any other social networking or similar site (collectively, “SNS”), an option of which may be provided to you by CLOUDKITCH at its sole discretion, you will be allowing us to pass through and receive from the SNS your log-in information and other user data; or</p></li>
                            <li><p>Provide content to us, which may include reviews, ordering details and history, favorite vendors, special merchant requests, contact information of people you refer to us and other information you provide on the CLOUDKITCH Platform <b>(“Your Content”)</b>.</p></li>
                            <li><p>Use our Services, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information.</p></li>
                            <li><p>Correspond with CLOUDKITCH for customer support;</p></li>
                            <li><p>Participate in the interactive services offered by the CLOUDKITCH Platform such as discussion boards, competitions, promotions or surveys, other social media functions or make payments etc., or</p></li>
                            <li><p>Enable features that require CLOUDKITCH's access to your address book or calendar;</p></li>
                            <li><p>Report problems for troubleshooting.</p></li>
                            <li><p>If you sign up to use our Services as a merchant or a delivery partner, we may collect location details, copies of government identification documents and other details (KYC), call and SMS details.</p></li>
                            <li><p><b>Information we collect about you -</b>&nbsp;With regard to each of your visits to the CLOUDKITCH Platform, we will automatically collect and analyse the following demographic and other information:</p></li>
                            <li><p>When you communicate with us (via email, phone, through the CLOUDKITCH Platform or otherwise), we may maintain a record of your communication;</p></li>
                            <li><p><b>Location information:</b> Depending on the Services that you use, and your app settings or device permissions, we may collect your real time information, or approximate location information as determined through data such as GPS, IP address;</p></li>
                            <li><p><b>Usage and Preference Information:</b> We collect information as to how you interact with our Services, preferences expressed and settings chosen. CLOUDKITCH Platform includes the CLOUDKITCH advertising services (“Ad Services”), which may collect user activity and browsing history within the CLOUDKITCH Platform and across third-party sites and online services, including those sites and services that include our ad pixels (“Pixels”), widgets, plug-ins, buttons, or related services or through the use of cookies. Our Ad Services collect browsing information including without limitation your Internet protocol (IP) address and location, your login information, browser type and version, date and time stamp, user agent, CLOUDKITCH cookie ID (if applicable), time zone setting, browser plug-in types and versions, operating system and platform, and other information about user activities on the CLOUDKITCH Platform, as well as on third party sites and services that have embedded our Pixels, widgets, plug-ins, buttons, or related services;</p></li>
                            <li><p><b>Transaction Information:</b> We collect transaction details related to your use of our Services, and information about your activity on the Services, including the full Uniform Resource Locators (URL), the type of Services you requested or provided, comments, domain names, search results selected, number of clicks, information and pages viewed and searched for, the order of those pages, length of your visit to our Services, the date and time you used the Services, amount charged, details regarding application of promotional code, methods used to browse away from the page and any phone number used to call our customer service number and other related transaction details;</p></li>
                            <li><p><b>Device Information:</b> We may collect information about the devices you use to access our Services, including the hardware models, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information and mobile network information. Analytics companies may use mobile device IDs to track your usage of the CLOUDKITCH Platform;</p></li>
                            <li><p><b>Stored information and files:</b>&nbsp; CLOUDKITCH mobile application (CLOUDKITCH app) may also access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts and address book information. If you permit the CLOUDKITCH app to access the address book on your device, we may collect names and contact information from your address book to facilitate social interactions through our services and for other purposes described in this Policy or at the time of consent or collection. If you permit the CLOUDKITCH app to access the calendar on your device, we collect calendar information such as event title and description, your response (Yes, No, Maybe), date and time, location and number of attendees.</p></li>
                            <li><p>If you are a partner restaurant, merchant or a delivery partner, we will, additionally, record your calls with us made from the device used to provide Services, related call details, SMS details location and address details.</p></li>
                            <li><p><b>Information we receive from other sources -</b></p></li>
                            <li><p>We may receive information about you from third parties, such as other users, partners (including ad partners, analytics providers, search information providers), or our affiliated companies or if you use any of the other websites/apps we operate or the other Services we provide. Users of our Ad Services and other third-parties may share information with us such as the cookie ID, device ID, or demographic or interest data, and information about content viewed or actions taken on a third-party website, online services or apps. For example, users of our Ad Services may also be able to share customer list information (e.g., email or phone number) with us to create customized audience segments for their ad campaigns.</p></li>
                            <li><p>When you sign in to CLOUDKITCH Platform with your SNS account, or otherwise connect to your SNS account with the Services, you consent to our collection, storage, and use, in accordance with this Policy, of the information that you make available to us through the social media interface. This could include, without limitation, any information that you have made public through your social media account, information that the social media service shares with us, or information that is disclosed during the sign-in process. &nbsp;Please see your social media provider’s privacy policy and help center for more information about how they share information when you choose to connect your account.</p></li>
                            <li><p>If you are partner restaurant, merchant or a delivery partner, we may, additionally, receive feedback and rating from other users.</p></li> 
                        </ul>
                        <h2 class="policyHead">COOKIES</h2>
                        <p>Our CLOUDKITCH Platform and third parties with whom we partner, may use cookies, pixel tags, web beacons, mobile device IDs, “flash cookies” and similar files or technologies to collect and store information with respect to your use of the Services and third-party websites.</p>
                        <p>Cookies are small files that are stored on your browser or device&nbsp;by websites, apps, online media and advertisements. We use cookies and similar technologies for purposes such as:</p>
                        <ul>
                            <li><p>Authenticating users;</p></li>
                            <li><p>Remembering user preferences and settings;</p></li>
                            <li><p>Determining the popularity of content;</p></li>
                            <li><p>Delivering and measuring the effectiveness of advertising campaigns;</p></li>
                            <li><p>Analysing site traffic and trends, and generally understanding the online behaviours and interests of people who interact with our services.</p></li>
                        </ul>
                        <p>A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier, embedded invisibly on a webpage (or an online ad or email), and is used to count or track things like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users’ computers. We use pixel tags to measure the popularity of our various pages, features and services. We also may include web beacons in e-mail messages or newsletters to determine whether the message has been opened and for other analytics.</p>
                        <p>To modify your cookie settings, please visit your browser’s settings. By using our Services with your browser settings to accept cookies, you are consenting to our use of cookies in the manner described in this section.</p>
                        <p>We may also allow third parties to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs and other technologies to identify your device when you visit the CLOUDKITCH Platform and use our Services, as well as when you visit other online sites and services.</p>
                        <p>Please see our&nbsp;Cookie Policy&nbsp;for more information regarding the use of cookies and other technologies described in this section, including regarding your choices relating to such technologies.</p>
                        <h2 class="policyHead">USES OF YOUR INFORMATION</h2>
                        <ul>
                            <li><p>We use the information we collect for following purposes, including:</p></li>
                            <li><p>To provide, personalise, maintain and improve our products and services, such as to enable deliveries and other services, enable features to personalise your CLOUDKITCH account;</p></li>
                            <li><p>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the relevant information and services;</p></li>
                            <li><p>To administer and enhance the security of our CLOUDKITCH Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</p></li>
                            <li><p>To provide you with information about services we consider similar to those that you are already using, or have enquired about, or may interest you. If you are a registered user, we will contact you by electronic means (e-mail or SMS or telephone) with information about these services;</p></li>
                            <li><p>To understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, make special offers, provide customer support, process and respond to your queries;</p></li>
                            <li><p>To generate and review reports and data about, and to conduct research on, our user base and Service usage patterns;</p></li>
                            <li><p>To allow you to participate in interactive features of our Services, if any; or</p></li>
                            <li><p>To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you.</p></li>
                            <li><p>If you are a partner restaurant or merchant or delivery partner, to track the progress of delivery or status of the order placed by our customers.</p></li>
                        </ul>
                        <p>We may combine the information that we receive from third parties with the information you give to us and information we collect about you for the purposes set out above. &nbsp;Further, we may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Policy, and it may be used and disclosed to others without limitation.</p>
                        <p>We analyse the log files of our CLOUDKITCH Platform that may contain Internet Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring, app crashes, page viewed and exit websites and applications, operating system, date/time stamp, and clickstream&nbsp;data. This helps us to administer the website, to learn about user behavior on the site, to improve our product and services, and to gather demographic information about our user base as a whole.</p>
                        <h2 class="policyHead">DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION</h2>
                        <p>We may share your information that we collect for following purposes:</p>
                        <ul>
                            <li><p><b>With Service Providers:</b>&nbsp;We may share your information with our vendors, consultants, marketing partners, research firms and other service providers or business partners, such as Payment processing companies, to support our business. For example, your information may be shared with outside vendors to send you emails and messages or push notifications to your devices in relation to our Services, to help us analyze and improve the use of our Services, to process and collect payments. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us.</p></li>
                            <li><p><b>With Partner Restaurants/Merchant:</b>&nbsp;While you place a request to order food through the CLOUDKITCH Platform, your information is provided to us and to the restaurants/merchants with whom you may choose to order. In order to facilitate your online food order processing, we provide your information to that restaurant/merchant in a similar manner as if you had made a food order directly with the restaurant. If you provide a mobile phone number, CLOUDKITCH may send you text messages regarding the order’s delivery status.</p></li>
                            <li><p><b>With Other Users:</b>&nbsp;If you are a delivery partner, we may share your name, phone number and/or profile picture (if applicable), tracking details with other users to provide them the Services.</p></li>
                            <li><p><b>For Crime Prevention or Investigation:</b>&nbsp;We may share this information with governmental agencies or other companies assisting us, when we are:</p></li>
                            <li><p>Obligated under the applicable laws or in good faith to respond to court orders and processes; or</p></li>
                            <li><p>Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of Services and other illegal acts;</p></li>
                            <li><p>Responding to claims that an advertisement, posting or other content violates the intellectual property rights of a third party;</p></li>
                            <li><p>Under a duty to disclose or share your personal data in order to enforce our Terms of Use and other agreements, policies or to protect the rights, property, or safety of the Company, our customers, or others, or in the event of a claim or dispute relating to your use of our Services.&nbsp;This includes exchanging information with other companies and organisations for the purposes of fraud detection and credit risk reduction.</p></li>
                            <li><p><b>For Internal Use:</b>&nbsp;We may share your information with any present or future member of our “Group” (as defined below)or affiliates for our internal business purposes The term “Group” means, with respect to any person, any entity that is controlled by such person, or any entity that controls such person, or any entity that is under common control with such person, whether directly or indirectly, or, in the case of a natural person, any Relative (as such term is defined in the Companies Act, 1956 and Companies Act, 2013 to the extent applicable) of such person.</p></li>
                            <li><p><b>With Advertisers and advertising networks:</b>&nbsp;We may work with third parties such as network advertisers to serve advertisements on the CLOUDKITCH Platform and on third-party websites or other media (e.g., social networking platforms). These third parties may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you.&nbsp;</p></li>
                            <li><p>While you cannot opt out of advertising on the CLOUDKITCH Platform, you may opt out of much interest-based advertising on third party sites and through third party ad networks (including DoubleClick Ad Exchange, Facebook Audience Network and Google AdSense). For more information, visit&nbsp;<a href="www.aboutads.info/choices" target="_blank">www.aboutads.info/choices</a>. Opting out means that you will no longer receive personalized ads by third parties ad networks from which you have opted out, which is based on your browsing information across multiple sites and online services. If you delete cookies or change devices, your opt out may no longer be effective.</p></li>
                            <li><p>To fulfill the purpose for which you provide it.</p></li>
                            <li><p>We may share your information other than as described in this Policy if we notify you and you consent to the sharing.</p></li>
                        </ul>
                        <h2 class="policyHead">DATA SECURITY PRECAUTIONS</h2>
                        <p>We have in place appropriate technical and security measures to secure the information collected by us.</p>
                        <p>We use vault and tokenization services from third party service providers to protect the sensitive personal information provided by you. The third-party service providers with respect to our vault and tokenization services and our payment gateway and payment processing are compliant with the payment card industry standard (generally referred to as PCI compliant service providers). You are advised not to send your full credit/debit card details through unencrypted electronic platforms. Where we have given you (or where you have chosen) a username and password which enables you to access certain parts of the CLOUDKITCH Platform, you are responsible for keeping these details confidential. We ask you not to share your password with anyone.</p>
                        <p>Please we aware that the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through the CLOUDKITCH Platform. Once we have received your information, we will use strict physical, electronic, and procedural safeguards to try to prevent unauthorised access.</p>
                        <h2 class="policyHead">OPT-OUT</h2>
                        <p>When you sign up for an account, you are opting in to receive emails from CLOUDKITCH. You can log in to manage your email preferences&nbsp;[here]&nbsp;or you can follow the “unsubscribe” instructions in commercial email messages, but note that you cannot opt out of receiving certain administrative notices, service notices, or legal notices from CLOUDKITCH.</p>
                        <p>If you wish to withdraw your consent for the use and disclosure of your personal information in the manner provided in this Policy, please write to us at [•]. Please note that we may take time to process such requests, and your request shall take effect no later than 5 (Five) business days from the receipt of such request, after which we will not use your personal data for any processing unless required by us to comply with our legal obligations. We may not be able offer you any or all Services upon such withdrawal of your consent.</p>
                        <h2 class="policyHead">GRIEVANCE OFFICER AND CLOUDKITCH PLATFORM SECURITY</h2>
                        <p>If you have any queries relating to the processing or usage of information provided by you in connection with this Policy, please email us at&nbsp;<a href="mailto:info@cloudkitch.co.in">info@cloudkitch.co.in</a>&nbsp;or write to our Grievance Officer at the following address:</p>
                        <p><b>CLOUDKITCH Grievance Officer</b></p>
                        <p>Cloud Kitch Private Limited,</p>
                        <p>Unit No. G4-A, Trade Centre, Bandra Kurla Complex, Bandra East, Mumbai-400051</p>
                        <p>If you come across any abuse or violation of the Policy, please report to&nbsp;<a href="mailto:info@cloudkitch.co.in">info@cloudkitch.co.in</a></p>
                        <p>Further, please note that the CLOUDKITCH Platform stores your data with the cloud platform of Amazon Web Services provided by Amazon Web Services, Inc., which may store this data on their servers located outside of India. Amazon Web Services has security measures in place to protect the loss, misuse and alteration of the information, details of which are available at <a href="https://aws.amazon.com/" target="_blank">https://aws.amazon.com/</a>. The privacy policy adopted by Amazon Web Services are detailed in&nbsp;<a href="https://aws.amazon.com/privacy/" target="_blank">https://aws.amazon.com/privacy.</a>&nbsp;In the event you have questions or concerns about the security measures adopted by Amazon Web Services, you can contact their data protection / privacy team / legal team or designated the grievance officer at these organisations, whose contact details are available in its privacy policy, or can also write to our Grievance Officer at the address provided above.</p>
                     </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

              <b-tab title="Cookie Policy" @click="pushPolicy('cookie-policy')" :active="policyType == 'cookie-policy'" title-item-class="mb-2">
                <b-card-text>
                    <div class="section-services container">
                        <h2 class="setionTitle align-center">Cookie Policy</h2>
                        <p>This Cookie Policy <b>(“Cookie Policy”)</b> is a part of and incorporated within and is to be read along with the Privacy Policy <b>(“Policy”)</b> and Term of Use. The capitalized terms used in this Cookie Policy, but not defined herein, shall have the meaning given to such terms in the Privacy Policy.</p>
                        <h2 class="policyHead">What are cookies and local storage?</h2>
                        <p>Cookies are small text files that are placed on your browser or device by websites, apps, online media, and advertisements. There are different types of cookies. Cookies served by the entity that operates the domain you are visiting are called “first party cookies.” So, cookies served by Cloudkitch while you are on&nbsp;the Cloudkitch Platform&nbsp;are first party cookies. Cookies served by companies that are not operating the domain you are visiting are called “third party cookies.” For eg., we may allow Google to set a cookie on your browser while you visit&nbsp;the Cloudkitch Platform, and that would be a third party cookie. Cookies may also endure for different periods of time. “Session Cookies” only last only as long as your browser is open. These are deleted automatically once you close your browser. Other cookies are “persistent cookies” meaning that they survive after your browser is closed. For example, they may recognize your device when you re-open your browser and browse the internet again.</p>
                        <p>“Pixel tags” (also called beacons or pixels) are small blocks of code installed on (or called by) a webpage, app, or advertisement which can retrieve certain information about your device and browser, including for example: device type, operating system, browser type and version, website visited, time of visit, referring website, IP address, and other similar information, including the small text file (the cookie) that uniquely identifies the device. Pixels provide the means by which third parties can set and read browser cookies from a domain that they do not themselves operate and collect information about visitors to that domain, typically with the permission of the domain owner.</p>
                        <p>Local storage is a technology that allows a website or application to store information locally on your device. “Software Development Kits” (also called SDKs) function like pixels and cookies, but operate in the mobile app context where pixels and cookies cannot always function. The primary app developer can install pieces of code (the SDK) from partners in the app, and thereby allow the partner to collect certain information about user interaction with the app and information about the user device and network information.</p>
                        <h2 class="policyHead">Why do we use these technologies?</h2>
                        <p>We use cookies and other identification technologies for various purposes, including: authenticating users,store information about you (including on your device or in your browser cache) and your use of our Services and the Cloudkitch Platform, remembering user preferences and settings, determining the popularity of content, delivering and measuring the effectiveness of advertising campaigns, analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our Services.</p>
                        <h2 class="policyHead">Cookies used by us</h2>
                        <table border="1" class="custTable">
                                        <tbody><tr>
                                            <th>
                                                <p><b>Type of Cookie</b></p>
                                            </th>
                                            <th>
                                                <p><b>Purpose</b></p>
                                            </th>
                                            <th>
                                                <p><b>Host</b></p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><b>Authentication Cookies</b></p>
                                            </td>
                                            <td>
                                                <p>These cookies (including local storage and similar technologies) tell us when you’re logged in, so we can show you the appropriate experience and features such as your account information, order history and to edit your account settings.</p>
                                            </td>
                                            <td>
                                                <p>Cloudkitch</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><b>Security and site integrity cookies</b></p>
                                            </td>
                                            <td>
                                                <p>We use these cookies to support or enable security features to help keep Cloudkitch safe and secure. For example, they enable us to remember when you are logged into a secure area of the Services and help protect your account from being accessed by anyone other than you.</p>
                                            </td>
                                            <td>
                                                <p>Cloudkitch</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><b>Site features and Services</b></p>
                                            </td>
                                            <td>
                                                <p>These provide functionality that help us deliver products and Services. For example, cookies help you log in by pre-filling fields. We may also use cookies and similar technologies to help us provide you and others with social plugins and other customized content and experiences, such as making suggestions to you and others.</p>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <p>Cloudkitch</p>
                                                    </li>
                                                    <li>
                                                        <p>Facebook</p>
                                                    </li>
                                                    <li>
                                                        <p>Google</p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <p><b>Analytics and research</b></p>
                                            </td>
                                            <td>
                                                <p>These are used to understand, improve, and research products and Services, including when you access the Cloudkitch website and related websites and apps from a computer or mobile device. For example, we may use cookies to understand how you are using site features, and segmenting audiences for feature testing. We and our partners may use these technologies and the information we receive to improve and understand how you use websites, apps, products, services and ads.</p>
                                            </td>
                                            <td>
                                                <p>Google</p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <p><b>Advertising</b></p>
                                            </td>
                                            <td>
                                                <p>Things like cookies and pixels are used to deliver relevant ads, track ad campaign performance and efficiency. For example, we and our ad partners may rely on information gleaned through these cookies to serve you ads that may be interesting to you on other websites. Similarly, our partners may use a cookie, attribution service or another similar technology to determine whether we’ve served an ad and how it performed or provide us with information about how you interact with them.</p>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <p>Cloudkitch</p>
                                                    </li>
                                                    <li>
                                                        <p>Facebook</p>
                                                    </li>
                                                    <li>
                                                        <p>Google</p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                        <h2 class="policyHead">Cookies used by us</h2>
                        <p>We use a number of suppliers that may also set cookies on your device on our behalf when you visit the Cloudkitch Platform to allow them to deliver the services they are providing.</p>
                        <p>When you visit the Cloudkitch Platform you may receive cookies from third party websites or domains. More information about these cookies may be available on the relevant third party's website.</p>
                        <h2 class="policyHead">Additional Information About Third Party Analytics in use on the Cloudkitch Platform :</h2>
                        <ul>
                            <li>
                                <p><b>Facebook Connect:</b>&nbsp;For more information about what Facebook collects when you use Facebook buttons on the Cloudkitch Platform, please see:&nbsp;Data Policy.</p>
                            </li>

                            <li>
                                <p><b>Twitter:</b>&nbsp;For more information about what Twitter collects when you use the Cloudkitch Platform, please see:&nbsp;<a href="https://twitter.com/en/privacy" target="_blank">https://twitter.com/en/privacy</a>.</p>
                            </li>
                            <li>
                                <p><b>Google Analytics:</b>&nbsp;For more information about Google Analytics cookies, please see Google's help pages and privacy policy:</p>
                                <ul>
                                    <li><a href="https://policies.google.com/privacy" target="_blank">Google's Privacy Policy</a></li>
                                    <li><a href="https://support.google.com/analytics/#topic=3544906" target="_blank">Google Analytics Help pages</a></li>
                                </ul>
                            </li>
                        </ul>
                        <p>Please note that this is not an exhaustive list of all third party analytics providers that we work with.</p>
                        <h2 class="policyHead">How can I control Cookies?</h2>
                        <p>Most internet browsers are initially set up to automatically accept cookies. You can change the settings to block cookies or to alert you when cookies are being sent to your device. There are a number of ways to manage cookies. Please refer to your browser instructions or help screen to learn more about how to adjust or modify your browser settings.</p>
                        <p>If you disable the cookies that we use, this may impact your experience while on the Cloudkitch Platform, for example you may not be able to visit certain areas of the Cloudkitch Platform or you may not receive personalised information when you visit the Cloudkitch Platform or you may also be unable to login to services or programs, such as logging into forums or accounts.</p>
                        <p>If you use different devices to view and access the Cloudkitch Platform(e.g. your computer, smartphone, tablet etc) you will need to ensure that each browser on each device is adjusted to suit your cookie preferences.</p>
                        <p><b>Changing your Cookie Settings.</b>&nbsp;The browser settings for changing your cookies settings are usually found in the 'options' or 'preferences' menu of your internet browser. In order to understand these settings, the following links may be helpful. Otherwise you should use the 'Help' option in your internet browser for more details.</p>
                        <ul>
                            <li>
                                <p><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Cookie settings in Internet Explorer</a></p>
                            </li>
                            <li>
                                <p><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Cookie settings in Firefox</a></p>
                            </li>
                            <li>
                                <p><a href="https://support.google.com/chrome/answer/95647" target="_blank">Cookie settings in Chrome</a></p>
                            </li>
                            <li>
                                <p><a href="https://support.apple.com/" target="_blank">Cookie settings in Safari</a></p>
                            </li>
                        </ul>
                        <p>More information.&nbsp;To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="https://www.aboutcookies.org/" target="_blank">About Cookies</a>&nbsp;&nbsp;or&nbsp;<a href="http://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a>.</p>

                    
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->
              <b-tab title="Cancellation & Refund" @click="pushPolicy('cancellation-refund')" :active="policyType == 'cancellation-refund'" title-item-class="mb-2">
                <b-card-text>
                    <div class="section-services container">
                        <h2 class="setionTitle align-center">Cancellations and Refunds</h2>
                        <h2 class="policyHead">Cancellations and Refunds</h2>
                        <ul>
                            <li>
                                <p><b><u>Cancellation</u></b></p>
                            </li>
                            <li>
                                <p>As a general rule you shall not be entitled to cancel your order once you have received confirmation of the same. If you cancel your order after it has been confirmed, CLOUDKITCH shall have a right to charge you cancellation fee of a minimum INR 75 upto the order value, with a right to either not to refund the order value or recover from your subsequent order, the complete/ deficit cancellation fee, as applicable, to compensate our restaurant and delivery partners. CLOUDKITCH shall also have right to charge you cancellation fee for the orders cancelled by CLOUDKITCH for the reasons specified under clause 1(iii) of this cancellation and refunds policy. In case of cancellations for the reasons attributable to CLOUDKITCH or its restaurant and delivery partners, CLOUDKITCH shall not charge you any cancellation fee.</p>
                            </li>
                            <li>
                                <p>However, in the unlikely event of an item on your order being unavailable, we will contact you on the phone number provided to us at the time of placing the order and inform you of such unavailability. In such an event you will be entitled to cancel the entire order and shall be entitled to a refund in accordance with our refund policy.</p>
                            </li>
                            <li>
                                <p>We reserve the sole right to cancel your order in the following circumstance:</p>
                            </li>
                            <li>
                                <p>In the event of the designated address falls outside the delivery zone offered by us;</p>
                            </li>
                            <li>
                                <p>Failure to contact you by phone or email at the time of confirming the order booking;</p>
                            </li>
                            <li>
                                <p>Failure to deliver your order due to lack of information, direction or authorization from you at the time of delivery; or</p>
                            </li>
                            <li>
                                <p>Unavailability of all the items ordered by you at the time of booking the order; or</p>
                            </li>
                            <li>
                                <p>Unavailability of all the items ordered by you at the time of booking the order; or</p>
                            </li>
                            <li>
                                <p><b><u>Refunds</u></b></p>
                            </li>
                            <li>
                                <p>You shall be entitled to a refund only if you pre-pay for your order at the time of placing your order on the Platform and only in the event of any of the following circumstances:</p>
                            </li>
                            <li>
                                <p>Your order packaging has been tampered or damaged at the time of delivery;</p>
                            </li>
                            <li>
                                <p>Us cancelling your order due to (A) your delivery location following outside our designated delivery zones; (B) failure to contact you by phone or email at the time of confirming the order booking; or (C) failure to contact you by phone or email at the time of confirming the order booking; or</p>
                            </li>
                            <li>
                                <p>You cancelling the order at the time of confirmation due to unavailability of the items you ordered for at the time of booking.</p>
                            </li>
                            <li>
                                <p>Our decision on refunds shall be at our sole discretion and shall be final and binding.</p>
                            </li>
                            <li>
                                <p>All refund amounts shall be credited to your account within 3-4 business days in accordance with the terms that may be stipulated by the bank which has issued the credit / debit card.</p>
                            </li>

                        </ul>
                        <table class="process-table custTable">
                                <tbody><tr>
                                    <th>Process</th>
                                    <th>Payment Method</th>
                                    <th>Refund Source</th>
                                    <th>TAT</th>
                                </tr>
                                <tr>
                                    <td rowspan="10">
                                        <p>Order Edit/Cancellation/Compensation/Payment Failure</p>
                                    </td>
                                    <td>
                                        <p>Net Banking</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>5-7 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Debit/Credit Cards</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>4-7 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>UPI</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>2-4 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Amazon Pay (Wallet)</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>5 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Amazon Pay (CC/DC/NB)</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>5 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Phone Pe (Wallet)</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>5 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Phone Pe (CC/DC/NB)</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>5 Business Days</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Wallets-Paytm/Mobikwik/Freecharge</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>1 Hour</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Lazy Pay</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>1 Hour</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sodexo</p>
                                    </td>
                                    <td>
                                        <p>Source</p>
                                    </td>
                                    <td>
                                        <p>5 Business Days</p>
                                    </td>
                                </tr>

                            </tbody></table>
                            <ul>
                                <li>
                                    <p>In case of payment at the time of delivery, you will not be required to pay for:</p>
                                </li>
                                <li>
                                    <p>Orders where the packaging has been tampered or damaged by us;</p>
                                </li>
                                <li>
                                    <p>Wrong order being delivered; or</p>
                                </li>
                                <li>
                                    <p>Items missing from your order at the time of delivery.</p>
                                </li>

                            </ul>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

            </b-tabs>
    </main>

    <loginRegModal />
    <footerLayout />
	<!-- footer layout -->
    </div>
</template>
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style>
.mainLogoImg{
	object-fit:cover;
    /* margin-left: 150px; */
}
.tabs{
    width: 100%;
}
.setionTitle {
    font-size: 30px;
    margin: 0 0 50px 0;
    color: #A4827D;
}
body{
    background: #fff!important;
}
.nav-pills{
    margin-top: 20px!important;

}
.tab-content{
    margin: 30px 0px!important;
    border-left: 1px solid #f1f1f1

}
.card-text{
    color: #A4827D;
}
.policyHead
{
    margin: 35px 0;
    margin-bottom: 10px;
    color: #A4827D;
    font-size: 24px;
}
ul, li{
    text-decoration: none;
    list-style: none;
    padding-left: 0px;
}
li h4{
    color: #A4827D;
    margin-top: 20px;
    margin-bottom: 5px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
        color: #000;
    background-color: #ffc60c;
}
.section-services p{
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 14px;
}
.section-services a{
    color: #F3766A;
}
.custTable {
    border: 1px solid #f1f1f1;
    margin-top: 30px;
    margin-bottom: 30px;
}
.custTable tr th {
    background-color: #f1f1f1;
    text-align: left;
}
table, tr, td, th {
    border-spacing: 0;
    padding: 0;
    margin: 0;
    vertical-align: top;
}
.custTable tr th, .custTable tr td {
    padding: 15px;
    border: 1px solid #f1f1f1;
}
.process-table{
    width: 100%;
}
#logo a
{
    color: #000;
}
#logo a:hover, .logo a:focus
{
    color: #F3766A;
}
/* .tabsPostion{
    border-right: 1px solid #f1f1f1;
    position: sticky;
    top: 90px;
    height: 100%;
} */
header{
    position: sticky!important;
}
.rightDiv a {
    color: #000;
}
.rightDiv a:hover, .rightDiv a:focus {
    color: #F3766A;
}
.rightDiv a i{
    margin-left: 5px;
    margin-right: 3px;
}
.home
{
    font-family: 'GothamMedium'!important;
    font-size: 16px;
    color: #212121;
}
a.phneNo, a.email{
    font-size: 14px;
    color: #212121;
}
@media (min-width: 575px){
    .tabsPostion{
        /* border-right: 1px solid #f1f1f1; */
        position: sticky;
        top: 90px;
        height: 100%;
        padding-left: 20px;
    }
}
.userPlaceImage
{
	object-fit: contain;
	border-radius: 25px;
}
.loginReg
{
	font-size: 18px;
	color: #F3766A;
}
.head_section
{
	margin-bottom: 20px;
}
/* .navigation_ul
{
	list-style-type: none;
	padding: 0;
} */
.navigation_ul li
{
	padding: 12px 0;
	font-size: 14px;
}
.navigation_ul a li
{
	color: #777;
}
.helpHead
{
	font-size: 16px;
	color: #777;
}
</style>